<template>
  <v-dialog v-model="dialog" persistent width="90%">
    <v-card class="mx-0 px-0">
      <v-card-title
        class="d-flex align-center justify-center"
        style="background: #7024c4; color: white;"
      >
        Answer a few questions that will help us know if your intake is
        completed.
      </v-card-title>
      <v-card-title class="mx-0 pa-0 d-flex" style="align-items: flex-start;">
        <div
          style="width: 300px; height: 80vh; background-color: #f4f9ff;"
          class="d-flex flex-column"
        >
          <div
            v-for="(item, index) in items"
            :key="item.index"
            class="d-flex flex-column"
            style="width: 100%"
          >
            <div
              class="d-flex align-center"
              style="height: 80px; justify-content: flex-start; width: 100%; cursor: pointer;"
              @click="onSelectItem(index)"
            >
              <div
                style="width: 18px; background-color: #e5d7f4; height: 100%;"
                v-if="index == selected"
              />
              <div style="width: 18px; height: 100%;" v-else />
              <div style="margin-left: 30px; font-size: 16px;flex-grow: 1">
                {{ item.title }}
              </div>
              <!-- <div class="completed-icon">
                <v-icon size="16">mdi-check</v-icon>
              </div> -->
            </div>
            <v-divider />
          </div>
        </div>
        <div
          style="padding-left: 50px; padding-top: 30px; width: calc(100% - 350px);"
        >
          <div
            style="height: 67vh; padding-right: 50px; padding-top: 10px; overflow-y: auto;"
            v-if="items[selected]"
          >
            <div v-for="child in items[selected].children" :key="child._id">
              <intake-input-form :question="child" :index="0" />
              <intake-repeater-input
                :parent="child"
                v-if="child.form_field == 'Repeater Element'"
              />
            </div>
          </div>
          <div
            style="display: flex; justify-content: flex-end; padding-right: 50px;"
          >
            <v-btn
              color="secondary"
              class="mb-3 mr-3"
              @click="onClose"
              v-text="'Cancel'"
              outlined
              width="150px"
            />
            <v-btn
              color="primary"
              class="mb-3 mr-3"
              @click="onNext"
              width="150px"
              :loading="loading"
            >
              Next
            </v-btn>
          </div>
        </div>
        <v-snackbar v-model="snackbar">
          {{ errorMessage }}
        </v-snackbar>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IntakeInputForm from "../../components/intake/IntakeInputForm.vue";
import IntakeRepeaterInput from "../../components/intake/IntakeRepeaterInput.vue";
export default {
  components: { IntakeInputForm, IntakeRepeaterInput },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onConfirm: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      questions: [],
      items: [],
      selected: 0,
    };
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      allQuestions: "question/getAllQuestions",
    }),
  },
  methods: {
    ...mapActions("intake", {
      saveIntakeWithoutCalc: "saveIntakeWithoutCalc",
      saveIntake: "saveIntake",
    }),
    onNext() {
      if (this.selected < this.items.length - 1) {
        //   this.loading = true;
        //   this.saveIntakeWithoutCalc()
        //     .then(() => {
        this.selected++;
        //       this.loading = false;
        //     })
        //     .catch((error) => {
        //       this.loading = false;
        //       console.log(error.response.data.message);
        //       this.showErrorMessage(error.response.data.message);
        //     });
      } else if (this.selected == this.items.length - 1) {
        this.loading = true;
        this.saveIntake()
          .then(() => {
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
            this.showErrorMessage(error.response.data.message);
          });
      } else {
        console.log(this.selected);
      }
    },
    onCancel() {
      this.onClose();
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onSelectItem(index) {
      this.selected = index;
    },
  },
  mounted() {
    // console.log(this.intakes);
    this.questions = this.allQuestions
      .filter((q) => q.intake)
      .sort((a, b) => {
        return a.intake - b.intake;
      });
    this.items = [];
    for (let i = 0; i < this.questions.length; i++) {
      if (
        this.items.length == 0 ||
        this.items[this.items.length - 1].index != this.questions[i].intake
      ) {
        this.items.push({
          title: this.questions[i].short_label
            ? this.questions[i].short_label
            : this.questions[i].label,
          index: this.questions[i].intake,
          children: [this.questions[i]],
        });
      } else {
        this.items[this.items.length - 1].children.push(this.questions[i]);
      }
    }
    console.log(this.items);
  },
};
</script>
<style scoped>
.item-small-label {
  color: #423f63;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
.completed-icon {
  width: 24px;
  height: 24px;
  background: #dcbdff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-right: 20px;
}
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
