<template>
  <div>
    <div class="d-flex flex-row mx-15 mt-10">
      <div class="d-flex flex-column pr-15">
        <div v-for="(question, key) in questions1" :key="key">
          <intake-input-form :question="question" :index="0" v-if="editable" />
          <intake-view-form :question="question" :index="0" v-else />
        </div>
      </div>
      <div class="d-flex flex-row">
        <div style="width: 1px; background: #c4c4c4c4; margin-bottom: 30px;" />
        <div class="d-flex flex-column pl-15">
          <div v-for="(question, key) in questions2" :key="key">
            <intake-input-form
              :question="question"
              :index="0"
              v-if="editable"
            />
            <intake-view-form :question="question" :index="0" v-else />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="section.key == 'let_us_know'"
      class="app-bold-font font-16 primary-color d-flex align-center ml-5"
    >
      <div class="d-flex align-center" @click="requestPermission">
        Medical Questions
        <v-switch v-model="isMedical" class="ml-3" :disabled="!permission" />
      </div>
    </div>
    <div
      v-for="(question, key) in questionsRepeater"
      :key="key"
      class="mx-3 mt-5"
    >
      <intake-repeater-input :parent="question" v-if="editable" />
      <intake-repeater-viewer :parent="question" v-else />
    </div>
    <permission-dialog
      :dialog="permissionDialog"
      :onPassed="onPassedPermission"
      :onCloseDialog="onClosePermissionDialog"
      v-if="permissionDialog"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PermissionDialog from "../client/PermissionDialog.vue";
import IntakeInputForm from "./IntakeInputForm.vue";
import IntakeRepeaterInput from "./IntakeRepeaterInput.vue";
import IntakeRepeaterViewer from "./IntakeRepeaterViewer.vue";
import IntakeViewForm from "./IntakeViewForm.vue";
export default {
  components: {
    IntakeInputForm,
    IntakeRepeaterInput,
    IntakeViewForm,
    IntakeRepeaterViewer,
    PermissionDialog,
  },
  props: {
    section: Object,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      questions1: [],
      questions2: [],
      questionsRepeater: [],
      isMedical: false,
      permissionDialog: false,
      permission: false,
    };
  },
  computed: {
    ...mapGetters({
      allQuestions: "question/getAllQuestions",
      medical: "question/getMedical",
    }),
  },
  methods: {
    ...mapActions({ setMedical: "question/setMedical" }),
    requestPermission() {
      if (!this.permission) this.permissionDialog = true;
    },
    onPassedPermission() {
      this.permissionDialog = false;
      this.permission = true;
    },
    onClosePermissionDialog() {
      this.permissionDialog = false;
    },
  },
  watch: {
    allQuestions(newValue) {
      this.questions1 = newValue
        .filter(
          (q) =>
            q.parent === this.section._id &&
            q.col === 1 &&
            (q.medical == this.isMedical || !q.medical)
        )
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
      this.questions2 = newValue
        .filter(
          (q) =>
            q.parent === this.section._id &&
            q.col === 2 &&
            (q.medical == this.isMedical || !q.medical)
        )
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
      this.questionsRepeater = newValue
        .filter(
          (q) =>
            q.parent === this.section._id && q.form_field === "Repeater Element"
        )
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
    medical(newValue) {
      if (this.isMedical != newValue) this.isMedical = newValue;
    },
    isMedical(newValue) {
      if (this.medical != newValue) {
        this.setMedical(newValue);
      }
      this.questions1 = this.allQuestions
        .filter(
          (q) =>
            q.parent === this.section._id &&
            q.col === 1 &&
            (q.medical == this.isMedical || !q.medical)
        )
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
      this.questions2 = this.allQuestions
        .filter(
          (q) =>
            q.parent === this.section._id &&
            q.col === 2 &&
            (q.medical == this.isMedical || !q.medical)
        )
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
  },
};
</script>
