<template>
  <v-container fluid style="margin: 0px; padding: 0px;height: 100%;">
    <Menu
      style="position:fixed; height: 50px; z-index: 1; left: 0; right: 0; background: #DBC7FF"
      title="Back"
      :question="true"
      :nutri_info="true"
      :summary="true"
      :intake_date="true"
      :handleBack="handleBack"
    />
    <div
      style="background: #F4F9FF; height: 100%; margin:0px; padding-top:50px; min-height: calc(100vh - 120px)"
      class="d-flex flex-row"
    >
      <client-info-card
        :id="this.$route.params.id"
        :onDeleted="onDeleted"
        ref="infoCard"
      />
      <div style="width: 100%; height: 100%; overflow-y: auto;">
        <v-row style="margin: 0">
          <v-col cols="12" v-for="(section, key) in sections" :key="key">
            <div class="item-title">{{ section.label }}</div>
            <v-divider class="mb-3" />
            <intake-section :section="section" class="mx-10" />
          </v-col>
        </v-row>
        <div
          style="display:flex; justify-content:flex-end; margin-top: 20px; margin-bottom: 20px; margin-right: 20px; flex-direction: row;"
        >
          <v-btn
            rounded
            color="primary"
            class="mb-3 mr-3"
            @click="onMakeReferral"
            outlined
          >
            make referral
          </v-btn>
          <v-btn
            rounded
            color="primary"
            class="mb-3 mr-3"
            @click="onSaveIntake"
            :loading="loading"
            outlined
          >
            calculate
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            class="mb-3 mr-3"
            @click="onNotQualify"
            :loading="doingNotQualify"
            outlined
            v-if="profile"
          >
            {{ profile.qualified ? "Do not Qualify" : "Set Qualify" }}
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            class="mb-3 mr-3"
            @click="onSave"
            :loading="onlySaving"
            outlined
          >
            Save
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            class="mb-3 mr-3"
            @click="onCancel"
            v-text="'Cancel'"
            outlined
          />
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <intake-result-dialog
      :dialog="completeDialog"
      :result="intakeResult"
      :profile="profile"
      :onConfirm="onConfirmResult"
      :onClose="onCloseResultDialog"
      v-if="completeDialog"
    />
    <client-intake-complete
      :dialog="intakeCompleteDialog"
      :onClose="onCloseIntakeCompleteDialog"
      v-if="intakeCompleteDialog"
    />
  </v-container>
</template>
<script>
import Menu from "../components/core/Menu.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import IntakeSection from "../components/intake/IntakeSection.vue";
import IntakeResultDialog from "../components/intake/IntakeResultDialog.vue";
import ClientInfoCard from "../components/client/ClientInfoCard.vue";
import ClientIntakeComplete from "./admin/ClientIntakeComplete.vue";
export default {
  components: {
    Menu,
    IntakeSection,
    IntakeResultDialog,
    ClientInfoCard,
    ClientIntakeComplete,
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      profile: null,
      intakeResult: {},
      completeDialog: false,
      intakeCompleteDialog: false,
      onlySaving: false,
      doingNotQualify: false,
    };
  },
  methods: {
    ...mapActions({
      getAllQuestions: "question/fetchAllQuestions",
      getAllIntakes: "intake/fetchAllIntakes",
      setSelectedId: "intake/setSelectedId",
      saveIntake: "intake/saveIntake",
      doNotQualify: "intake/doNotQualify",
      getClient: "client/getClient",
    }),
    onSaveIntake() {
      this.loading = true;
      this.saveIntake()
        .then((res) => {
          this.loading = false;
          this.intakeResult = res;
          this.completeDialog = true;
          this.$refs.infoCard.getData();
          if (this.$route.query.date) {
            this.$router.replace({
              query: {},
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onSave() {
      this.onlySaving = true;
      this.saveIntake()
        .then(() => {
          this.onlySaving = false;
          this.$refs.infoCard.getData();
          if (this.$route.query.date) {
            this.$router.replace({
              query: {},
            });
          }
          // this.$router.push({ name: "clientsHome" });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            if (this.onlySaving) {
              console.log(error.response.data.message);
            } else {
              this.snackbar = true;
              this.errorMessage = error.response.data.message;
            }
            this.onlySaving = false;
          }
          this.onlySaving = false;
        });
    },
    onNotQualify() {
      this.doingNotQualify = true;
      this.saveIntake()
        .then(() => {
          this.doingNotQualify = false;
          this.setQualfied();
        })
        .catch((error) => {
          this.doingNotQualify = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    setQualfied() {
      this.doingNotQualify = true;
      this.doNotQualify(!this.profile.qualified)
        .then(() => {
          this.doingNotQualify = false;
          // this.$router.push({ name: "clientsHome" });
        })
        .catch((error) => {
          this.doingNotQualify = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onCancel() {
      this.$router.push({ name: "clientsHome" });
    },
    onConfirmResult() {
      this.completeDialog = false;
      this.$refs.infoCard.getData();
    },
    onCloseResultDialog() {
      this.completeDialog = false;
    },
    onMakeReferral() {
      this.$router.push({
        name: "add-referral",
        query: { to: this.profile.client_id },
      });
    },
    onDeleted() {
      this.$router.back();
    },
    onCloseIntakeCompleteDialog() {
      this.intakeCompleteDialog = false;
    },
    checkIncompletedIntake() {
      if (this.$route.query.new) {
        this.intakeCompleteDialog = true;
      }
    },
    handleBack() {
      this.$router.push({ name: "clientsHome" });
    },
  },
  computed: {
    ...mapState("question", {
      sections: "sections",
      allQuestions: "questions",
    }),
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      unsavedChanges: "intake/unsavedChanges",
    }),
  },
  watch: {
    "$route.query": function(newValue) {
      this.getAllIntakes({ date: newValue.date }).catch((error) =>
        console.log(error.response.data.message)
      );
    },
  },
  mounted() {
    this.loading = true;
    this.setSelectedId(this.$route.params.id);
    this.getAllQuestions()
      .then(() => {
        this.loading = false;
        ////////////////////
        this.checkIncompletedIntake();
        /////////////
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
    this.getClient(this.$route.params.id).then((res) => {
      this.profile = res;
    });

    window.onbeforeunload = () => (this.unsavedChanges ? true : null);
  },
};
</script>
<style scoped>
.item-title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
</style>
