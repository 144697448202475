<template>
  <div v-if="condition">
    <!-- <intake-input-form :question="parent" :index="0" /> -->
    <v-row v-if="repeatCount">
      <v-col
        :cols="12 / questions.length"
        class="item-small-label mt-1"
        v-for="(question, key) in questions"
        :key="key"
        >{{ question.label }}
      </v-col>
    </v-row>
    <v-row v-for="index in repeatCount" :key="index">
      <v-col
        :cols="12 / questions.length"
        v-for="(question, key) in questions"
        :key="key"
        :index="index"
      >
        <intake-view-form
          :question="question"
          :index="index"
          :children="true"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import IntakeViewForm from "./IntakeViewForm.vue";
export default {
  components: { IntakeViewForm },
  props: {
    parent: Object,
  },
  data() {
    return {
      questions: [],
      repeatCount: 0,
      condition: true,
    };
  },
  computed: {
    ...mapGetters({
      allQuestions: "question/getAllQuestions",
      intakes: "intake/getAllIntakes",
    }),
  },
  watch: {
    allQuestions(newValue) {
      this.questions = newValue
        .filter((q) => q.parent === this.parent._id)
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
    "$store.state.intake.intakes": {
      handler() {
        this.repeatCount = parseInt(this.intakes[`${this.parent._id}_0`]) ?? 0;
        if (isNaN(this.repeatCount)) {
          this.repeatCount = 0;
        }
        if (this.repeatCount > 10) {
          this.repeatCount = 10;
        }
        if (this.parent.conditional) {
          if (
            this.intakes[`${this.parent.condition_id}_0`] == "true" &&
            this.parent.condition_value == "Is on"
          ) {
            this.condition = true;
          } else {
            this.condition = false;
          }
        } else {
          this.condition = true;
        }
      },
    },
  },
  mounted() {
    this.questions = this.allQuestions
      .filter((q) => q.parent === this.parent._id)
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        }
        if (a.order) return -1;
        if (b.order) return 1;
        if (a._id > b._id) return 1;
        else if (a._id < b._id) return -1;
        return 0;
      });
    this.repeatCount = parseInt(this.intakes[`${this.parent._id}_0`]) ?? 0;
    if (isNaN(this.repeatCount)) {
      this.repeatCount = 0;
    }
    if (this.parent.conditional) {
      if (
        this.intakes[`${this.parent.condition_id}_${this.index}`] == "true" &&
        this.parent.condition_value == "Is on"
      ) {
        this.condition = true;
      } else {
        this.condition = false;
      }
    } else {
      this.condition = true;
    }
  },
};
</script>
<style scoped>
.item-small-label {
  color: #423f63;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
</style>
