<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-text class="pt-10">
        <div
          class="rounded-lg px-15 pb-8"
          style="align-items: center; display: flex; flex-direction: column; background: #f4f9ff"
        >
          <span class="result-title pt-10" v-text="'Intake Completed!'" />
          <div class="result-row pt-5">
            <span class="result-line" v-text="'The calculation result'" />
            <span class="result-colon" v-text="':'" />
            <span class="result-value" v-text="result.meals" />
          </div>
          <div style="width: 100%" class="mt-1"><v-divider /></div>
          <div class="result-row">
            <div>
              <span>Fruits: </span>
              <span class="result-small-value" v-text="result.fruits" />
            </div>
            <div>
              <span>Vegetables: </span>
              <span class="result-small-value" v-text="result.vegetables" />
            </div>
            <div>
              <span>Grains: </span>
              <span class="result-small-value" v-text="result.grains" />
            </div>
            <div>
              <span>Protein: </span>
              <span class="result-small-value" v-text="result.protein" />
            </div>
            <div>
              <span>Dairy: </span>
              <span class="result-small-value" v-text="result.dairy" />
            </div>
          </div>
          <div style="width: 100%"><v-divider /></div>
          <div
            class="result-row pt-3"
            v-if="profile && profile.intake_nutriment"
          >
            <span class="result-line" v-text="'Previous taken result'" />
            <span class="result-colon" v-text="':'" />
            <span
              class="result-value"
              v-text="profile.intake_nutriment.meals"
            />
          </div>
          <span
            style="font-size: 10px; color: #515365;width: 100%;"
            v-if="profile && profile.intake_date"
            v-text="'(taken ' + getTimeAgo(profile.intake_date) + ')'"
          />
          <div class="result-row">
            <span class="result-line" v-text="'Current Balance'" />
            <span class="result-colon" v-text="':'" />
            <span
              class="result-value"
              v-text="
                profile && profile.rest_balance && profile.rest_balance.meals
                  ? profile.rest_balance.meals
                  : 0
              "
            />
          </div>
          <div class="result-row" v-if="profile && profile.rest_balance">
            <div>
              <span>Fruits: </span>
              <span
                class="result-small-value"
                v-text="profile.rest_balance.fruits"
              />
            </div>
            <div>
              <span>Vegetables: </span>
              <span
                class="result-small-value"
                v-text="profile.rest_balance.vegetables"
              />
            </div>
            <div>
              <span>Grains: </span>
              <span
                class="result-small-value"
                v-text="profile.rest_balance.grains"
              />
            </div>
            <div>
              <span>Protein: </span>
              <span
                class="result-small-value"
                v-text="profile.rest_balance.protein"
              />
            </div>
            <div>
              <span>Dairy: </span>
              <span
                class="result-small-value"
                v-text="profile.rest_balance.dairy"
              />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-0 pl-15 pt-0"
      >
        <v-btn
          color="blue white"
          @click="onClose"
          rounded
          text
          v-text="'Cancel'"
        />

        <v-btn
          color="blue white"
          @click="onConfirmResult"
          class="white--text"
          :loading="loading"
          rounded
        >
          Update Balance
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    result: Object,
    profile: Object,
    onClose: {
      type: Function,
    },
    onConfirm: {
      type: Function,
    },
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      setBalance: "client/setBalance",
    }),
    getTimeAgo(oldTimeStr) {
      const oldTime = Date.parse(oldTimeStr);
      const time = new Date();
      const different = (time.getTime() - oldTime) / 1000;
      const month = (different / 3600 / 24 / 30) | 0;
      const days = (different / 3600 / 24) % 30 | 0;
      const hours = (different / 3600) % 24 | 0;
      const mins = (different / 60) % 60 | 0;
      if (month == 1) return `1 month ago`;
      if (month) return `${month} months ago`;
      if (days == 1) return `1 day ago`;
      if (days) return `${days} days ago`;
      if (hours == 1) return `1 hours ago`;
      if (hours) return `${hours} hours ago`;
      if (mins == 1) return `1 min ago`;
      if (mins) return `${mins} min ago`;
      return `less than 1 min ago`;
    },
    onConfirmResult() {
      var params = {
        type: "Food",
        user_id: this.profile._id,
        amount: this.result.meals,
        intake_nutriment: this.result,
        intake: true,
      };
      this.loading = true;
      this.setBalance(params)
        .then(() => {
          this.loading = false;
          this.onConfirm();
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorMessage(error);
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
};
</script>
<style>
.result-title {
  font-size: 20px;
  color: #7024c4;
  font-weight: 500;
  font-family: "Poppins-SemiBold";
}
.result-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.result-line {
  font-size: 14px;
  color: #482684;
  font-family: "Poppins-SemiBold";
  width: 160px;
}
.result-colon {
  font-size: 14px;
  color: #482684;
  font-family: "Poppins-SemiBold";
}
.result-value {
  font-size: 18px;
  color: #7024c4;
  font-family: "Poppins-Bold";
  width: 50px;
}
.result-small-value {
  color: #7024c4;
  font-family: "Poppins-Bold";
  font-weight: 500;
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
