<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card v-if="noSetAccount">
      <v-card-title class="d-flex justify-end">
        <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center">
        <v-img
          src="@/assets/icon_chit_chat.svg"
          height="60"
          width="60"
          contain
        />
        <div class="mt-5 text-center">
          <span class="app-bold-font">Chit Chat Family</span> account for this
          person is not set<br />
          up yet, would you like to set it up now?
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-5">
        <v-btn color="primary" @click="setChitChatFamily" :loading="loading">
          Configure now
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="d-flex justify-between">
        Chit Chat Family Account
        <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-column align-center h-100" cols="4">
            <editable-avatar
              :onImageSelected="onImageSelected"
              :image="account.image"
              class="mt-4"
            />
            <div class="mt-2 w-100">
              Id
            </div>
            <v-text-field
              dense
              filled
              outlined
              v-model="account.login_id"
              hide-details
              class="mb-2 w-100"
              background-color="white"
            />
            <div class="mt-2 w-100">
              Name
            </div>
            <v-text-field
              dense
              filled
              outlined
              v-model="account.name"
              hide-details
              class="mb-2 w-100"
              background-color="white"
            />
            <div class="w-100">
              Gender
            </div>
            <v-select
              dense
              filled
              outlined
              v-model="account.gender"
              :items="genderOptions"
              hide-details
              class="mb-2 w-100"
              background-color="white"
            />
            <div class="w-100">
              Relation
            </div>
            <v-combobox
              dense
              filled
              outlined
              v-model="account.relation"
              :items="relationOptions"
              hide-details
              class="mb-2 w-100"
              background-color="white"
              autocomplete="off"
            />
            <input type="text" style="height: 0" />
            <div class="w-100">
              Password
            </div>
            <v-text-field
              dense
              filled
              outlined
              v-model="account.password"
              hide-details
              type="password"
              class="mb-2 w-100"
              background-color="white"
            />
          </v-col>
          <v-col class="h-100 position-relative" cols="8">
            <v-tabs class="mb-15">
              <v-tab>
                Family
              </v-tab>
              <v-tab>
                Friends
              </v-tab>
              <v-tab>
                Photo
              </v-tab>
              <v-tab-item>
                <v-container fluid>
                  <div
                    v-if="account.families && account.families.length"
                    style="max-height:374px; overflow:auto"
                  >
                    <div v-for="family in account.families" :key="family._id">
                      <chit-chat-family-account-dialog-family-list-item
                        :family="family"
                      />
                    </div>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container fluid>
                  <div
                    v-if="account.contacts && account.contacts.length"
                    style="max-height:374px; overflow:auto"
                  >
                    <div v-for="family in account.contacts" :key="family._id">
                      <chit-chat-family-account-dialog-family-list-item
                        :family="family"
                      />
                    </div>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container fluid>
                  PHoto content
                </v-container>
              </v-tab-item>
            </v-tabs>
            <div style="position: absolute; bottom: 10px; right: 10px;">
              <v-btn color="primary" @click="onSave" :loading="loading">
                Save
              </v-btn>
              <v-btn class="ml-3" @click="onCloseDialog">Close</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";
import ChitChatFamilyAccountDialogFamilyListItem from "./ChitChatFamilyAccountDialogFamilyListItem.vue";
export default {
  components: { EditableAvatar, ChitChatFamilyAccountDialogFamilyListItem },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
    },
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      noSetAccount: false,
      account: {},
      genderOptions: ["", "Male", "Female"],
      relationOptions: [],
      loading: false,
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      getValues: "variable/getValues",
      getFamily: "family/getFamily",
      setFamilies: "family/setFamilies",
      updateFamily: "family/editFamily",
    }),
    onImageSelected(image) {
      this.selectedFile = image;
    },
    setChitChatFamily() {
      this.loading = true;
      this.setFamilies({
        client_id: this.client_id,
        selected_index: this.index,
      })
        .then((res) => {
          this.account = res;
          this.noSetAccount = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    onSave() {
      this.loading = true;
      var formData = new FormData();
      Object.keys(this.account).map((key) => {
        if (this.account[key]) formData.append(key, this.account[key]);
      });
      if (this.selectedFile) {
        formData.append("image", this.selectedFile);
      }
      this.updateFamily(formData)
        .then((res) => {
          this.loading = false;
          this.error = null;
          this.account = res;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            console.log(error.response.data.message);
            this.error = error.response.data.message;
          } else {
            console.log(error);
          }
        });
    },
  },
  computed: {
    ...mapGetters("intake", {
      client_id: "selectedClientId",
    }),
  },
  mounted() {
    this.getValues({ title: "Relation" })
      .then((res) => {
        this.relationOptions = res.map((v) => v.text).sort();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getFamily({ client_id: this.client_id, index: this.index })
      .then((res) => {
        this.noSetAccount = false;
        this.account = res;
      })
      .catch((error) => {
        if (error.response.data.message == "Family not found") {
          this.noSetAccount = true;
        } else {
          console.log(error.response.data.message);
        }
      });
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate {
  transform: none !important;
}
</style>
