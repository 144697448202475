<template>
  <v-row
    v-if="
      condition &&
        (value || alterValue || arrayValue.length) &&
        question.form_field != 'File Input'
    "
    :class="{ 'mb-3': question.form_field == 'Toggle Input' }"
  >
    <v-col
      :cols="
        question.form_field == 'Toggle Input' ||
        question.form_field == 'Repeater Element' ||
        question.key == 'total' ||
        question.form_field == 'File Input'
          ? question.form_field == 'Toggle Input'
            ? 9
            : 6
          : 12
      "
      class="item-label mt-1"
      v-if="!children && question.form_field != 'Textarea'"
      v-text="question.label"
    />
    <v-col
      :cols="
        (children || question.form_field != 'Toggle Input') &&
        question.form_field != 'Repeater Element' &&
        question.key != 'total' &&
        question.form_field != 'File Input'
          ? 12
          : question.form_field == 'Toggle Input'
          ? 3
          : 6
      "
      v-if="!children"
    >
      <v-text-field
        v-if="
          question.form_field == 'Text Input' && question.content_type == 'Date'
        "
        v-model="value"
        readonly
        dense
        filled
        outlined
        background-color="white"
        :messages="
          question.key == 'birthday' && age
            ? 'Age: ' + age
            : question.key == 'birthday' && age == 0
            ? 'Age: < 1'
            : null
        "
        :style="children ? 'font-size: 12px' : ''"
        disabled
      />
      <v-text-field
        v-else-if="
          question.form_field == 'Text Input' ||
            question.form_field == 'Repeater Element'
        "
        dense
        filled
        outlined
        :type="question.form_field == 'Repeater Element' ? 'number' : 'text'"
        background-color="white"
        v-model="value"
        disabled
        :rules="[rules.required]"
        :style="children ? 'font-size: 12px' : ''"
        :readonly="question.key == 'total'"
      />
      <v-textarea
        v-if="question.form_field == 'Textarea'"
        rows="2"
        dense
        filled
        auto-grow
        outlined
        background-color="white"
        :placeholder="question.placeholder"
        v-model="value"
        disabled
        :rules="[rules.required]"
        :style="children ? 'font-size: 12px' : ''"
      />
      <v-select
        v-if="
          question.form_field == 'Select' &&
            question.multiple &&
            question.select_options == '#ORGANIZATION#'
        "
        :items="selectOptions"
        chips
        multiple
        clearable
        deletable-chips
        outlined
        filled
        background-color="white"
        v-model="arrayValue"
        disabled
        :style="children ? 'font-size: 12px' : ''"
        item-value="_id"
      >
        <template v-slot:selection="data">
          <v-chip>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <editable-avatar
              :image="data.item.image"
              :size="40"
              :editable="false"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" />
          </v-list-item-content>
        </template>
      </v-select>
      <v-select
        v-else-if="question.form_field == 'Select' && !question.multiple"
        :items="selectOptions"
        dense
        outlined
        filled
        background-color="white"
        v-model="value"
        disabled
        :style="children ? 'font-size: 12px' : ''"
      />
      <v-select
        v-else-if="question.form_field == 'Select' && question.multiple"
        :items="selectOptions"
        chips
        multiple
        outlined
        filled
        background-color="white"
        v-model="arrayValue"
        disabled
        :style="children ? 'font-size: 12px' : ''"
      />

      <v-text-field
        v-if="question.form_field == 'Select' && value == 'Other'"
        auto-grow
        rows="2"
        dense
        filled
        outlined
        type="text"
        background-color="white"
        v-model="alterValue"
        disabled
        :style="children ? 'font-size: 12px' : ''"
      />
      <!-- <radio-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Radio Input'"
    /> -->
      <!-- <checkbox-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Checkbox Input'"
    /> -->
      <div
        v-if="question.form_field == 'Toggle Input'"
        class="d-flex"
        :class="{ switch: !children }"
        v-show="condition"
      >
        <v-switch
          class="pt-0 mt-0"
          v-model="value"
          @change="onChange"
          disabled
        />
        <div class="ml-2" :style="children ? 'font-size: 12px;' : ''">
          {{ value ? "Yes" : "No" }}
        </div>
      </div>
    </v-col>
    <v-col cols="12" v-else>
      <v-text-field
        dense
        filled
        outlined
        background-color="white"
        v-model="value"
        readonly
        :style="children ? 'font-size: 12px' : ''"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    question: Object,
    index: {
      type: Number,
      default: 0,
    },
    children: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectOptions: [],
      value: "",
      alterValue: "",
      menu: false,
      condition: true,
      required: false,
      rules: {
        required: (value) => {
          if (this.required && !value) return "*Required";
          return true;
        },
      },
      arrayValue: [],
      age: 0,
      uploading: false,
    };
  },
  methods: {
    ...mapActions({
      setIntake: "intake/setIntake",
      getTotalWIC: "intake/getTotalWIC",
      getValues: "variable/getValues",
      uploadDocumentFile: "intake/uploadDocumentFile",
      fetchAllOrganizations: "partner/fetchAllOrganizations",
    }),
    onChange() {
      if (this.question.multiple) {
        this.value = this.arrayValue.join();
      }
      if (this.question.key == "birthday") {
        if (this.value == null) this.value = "";
        this.age = this.getAge();
      }
      if (
        this.question.form_field == "Select" &&
        this.value == "Other" &&
        this.alterValue
      ) {
        this.setIntake({
          _id: this.question._id,
          value: `${this.alterValue}`,
          index: this.index,
        });
      } else if (
        this.question.form_field == "Select" &&
        this.value == "Notes" &&
        this.alterValue
      ) {
        this.setIntake({
          _id: this.question._id,
          value: `${this.alterValue}`,
          index: this.index,
        });
      } else {
        this.setIntake({
          _id: this.question._id,
          value: `${this.value}`,
          index: this.index,
        });
      }
    },
    getAge() {
      if (this.value == "" || this.value == undefined) return null;
      var today = new Date();
      var birthDate = new Date(this.value);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    onSaveDate() {
      this.$refs.menu.save(this.value);
      this.setIntake({
        _id: this.question._id,
        value: this.value,
        index: this.index,
      });
    },
    onLoadData() {
      if (this.question.key == "total") {
        this.getTotalWIC().then((res) => {
          this.value = res;
        });
      } else if (this.question.form_field === "Select") {
        if (
          this.question.multiple &&
          this.question.select_options == "#ORGANIZATION#"
        ) {
          this.fetchAllOrganizations()
            .then((res) => {
              this.selectOptions = res;
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        } else if (
          this.question.from_default_option &&
          this.selectOptions.length == 0
        ) {
          this.getValues({ title: this.question.default_option })
            .then((values) => {
              this.loading = false;
              this.selectOptions = values.map((v) => v.text).sort();
            })
            .catch((error) => {
              this.loading = false;
              if (
                error.response != undefined &&
                error.response.status === 401
              ) {
                this.tokenDialog = true;
              } else {
                console.log(error);
              }
            });
        } else if (this.question.select_options) {
          this.selectOptions = this.question.select_options.split(",");
        }
        if (
          this.question.multiple &&
          this.intakes[`${this.question._id}_${this.index}`]
        ) {
          this.arrayValue = this.intakes[
            `${this.question._id}_${this.index}`
          ].split(",");
        } else if (this.question.multiple) {
          this.arrayValue = [];
        } else {
          var value = this.intakes[`${this.question._id}_${this.index}`];
          if (
            value &&
            !this.selectOptions.includes(value) &&
            this.selectOptions.includes("Other")
          ) {
            this.value = "Other";
            this.alterValue = value;
          } else if (
            value &&
            !this.selectOptions.includes(value) &&
            this.selectOptions.includes("Notes")
          ) {
            this.value = "Notes";
            this.alterValue = value;
          } else {
            this.value = this.intakes[`${this.question._id}_${this.index}`];
          }
        }
      } else if (this.question.form_field == "Toggle Input") {
        this.value =
          this.intakes[`${this.question._id}_${this.index}`] == "true";
      } else if (this.question.form_field === "File Input") {
        if (
          this.question.multiple &&
          this.intakes[`${this.question._id}_${this.index}`]
        ) {
          this.arrayValue = this.intakes[
            `${this.question._id}_${this.index}`
          ].split(",");
        } else if (this.question.multiple) {
          this.arrayValue = [];
        } else {
          value = this.intakes[`${this.question._id}_${this.index}`];
        }
      } else {
        this.value = this.intakes[`${this.question._id}_${this.index}`];
      }
      if (this.question.conditional) {
        if (
          this.intakes[`${this.question.condition_id}_${this.index}`] ==
            "true" &&
          this.question.condition_value == "Is on"
        ) {
          this.condition = true;
        } else if (
          this.intakes[`${this.question.condition_id}_${this.index}`] ==
          this.question.condition_value
        ) {
          this.condition = true;
        } else {
          this.condition = false;
          this.value = "";
        }
      } else {
        this.condition = true;
      }
      this.required = this.question.required;

      if (this.question.key == "birthday") {
        this.age = this.getAge();
      }
    },
    uploadFile() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.uploading = true;
        var formData = new FormData();
        if (this.question.multiple && e.target.files.length > 1) {
          for (var i = 0; i < e.target.files.length; i++) {
            formData.append("file", e.target.files[i]);
          }
        } else {
          formData.append("file", e.target.files[0]);
        }
        formData.append("index", this.index);
        formData.append("question_id", this.question._id);
        formData.append("client_id", this.client_id);
        formData.append(
          "multiple",
          this.question.multiple && e.target.files.length > 1
        );
        this.uploadDocumentFile(formData)
          .then((res) => {
            this.uploading = false;
            this.arrayValue.push(...res);
            this.onChange();
          })
          .catch((error) => {
            this.uploading = false;
            console.log(error.response.data.message);
          });
      }
    },
    onDeletedImage(image) {
      if (this.question.multiple) {
        this.arrayValue = this.arrayValue.filter((v) => image != v);
      } else {
        this.value = "";
      }
      this.onChange();
    },
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      client_id: "intake/selectedId",
      profileType: "auth/getType",
    }),
  },
  watch: {
    intakes() {
      this.onLoadData();
    },
  },
  mounted() {
    this.onLoadData();
  },
};
</script>
<style scoped>
.switch {
  display: flex;
  justify-content: flex-end;
}
.item-label {
  color: #472583;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
}
div >>> .v-date-picker-table {
  height: auto;
}
div >>> .v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}
div >>> .v-chip--disabled {
  opacity: 1 !important;
}
div >>> .v-select .v-select__selection--disabled {
  color: black;
}
div >>> .v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black;
}
div >>> .v-input--is-disabled textarea {
  color: black;
}
</style>
