<template>
  <v-row
    v-if="condition"
    :class="{ 'mb-3': question.form_field == 'Toggle Input' }"
  >
    <v-col
      :cols="
        question.form_field == 'Toggle Input' ||
        question.form_field == 'Repeater Element' ||
        question.key == 'total' ||
        question.form_field == 'File Input'
          ? question.form_field == 'Toggle Input'
            ? 9
            : 6
          : 12
      "
      class="item-label mt-1"
      v-if="!children && question.form_field != 'Textarea'"
      v-text="question.label"
    />
    <v-col
      :cols="
        (children || question.form_field != 'Toggle Input') &&
        question.form_field != 'Repeater Element' &&
        question.key != 'total' &&
        question.form_field != 'File Input'
          ? 12
          : question.form_field == 'Toggle Input'
          ? 3
          : 6
      "
    >
      <v-menu
        v-if="
          question.form_field == 'Text Input' && question.content_type == 'Date'
        "
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="value"
        transition="scale-transition"
        offset-x
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value"
            v-bind="attrs"
            v-on="on"
            dense
            filled
            outlined
            background-color="white"
            :messages="
              question.key == 'birthday' && age
                ? 'Age: ' + age
                : question.key == 'birthday' && age == 0
                ? 'Age: < 1'
                : null
            "
            :style="
              children ? 'font-size: 12px; min-width: 116px; width: 120px;' : ''
            "
            clearable
            @input="onChange"
            @keypress.enter="onSaveDate"
          />
        </template>
        <v-date-picker v-model="alterValue" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveAltDate">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-text-field
        v-else-if="
          question.form_field == 'Text Input' ||
            question.form_field == 'Repeater Element'
        "
        dense
        filled
        outlined
        :type="question.form_field == 'Repeater Element' ? 'number' : 'text'"
        background-color="white"
        v-model="value"
        @input="onChange"
        :rules="[rules.required]"
        :style="
          children && question.key == 'name'
            ? 'font-size: 12px; min-width: 120px; padding: 0'
            : children
            ? 'font-size: 12px; min-width: 50px; padding: 0'
            : ''
        "
        :readonly="question.key == 'total' || question.key == 'bmi'"
        :messages="
          question.key == 'total' && alterValue
            ? `Calculated needs days: ${alterValue}`
            : ''
        "
      />
      <v-textarea
        v-if="question.form_field == 'Textarea'"
        rows="2"
        dense
        filled
        auto-grow
        outlined
        background-color="white"
        :placeholder="question.placeholder"
        v-model="value"
        @input="onChange"
        :rules="[rules.required]"
        :style="children ? 'font-size: 12px' : ''"
      />
      <v-select
        v-if="
          question.form_field == 'Select' &&
            question.multiple &&
            question.select_options == '#ORGANIZATION#'
        "
        :items="selectOptions"
        chips
        multiple
        clearable
        deletable-chips
        outlined
        filled
        background-color="white"
        v-model="arrayValue"
        @input="onChange"
        :style="children ? 'font-size: 12px' : ''"
        item-value="_id"
      >
        <template v-slot:selection="data">
          <v-chip>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <editable-avatar
              :image="data.item.image"
              :size="40"
              :editable="false"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name" />
          </v-list-item-content>
        </template>
      </v-select>
      <v-select
        v-else-if="question.form_field == 'Select' && !question.multiple"
        :items="selectOptions"
        dense
        outlined
        filled
        background-color="white"
        v-model="value"
        @input="onChange"
        :style="children ? 'font-size: 12px; min-width: 100px;' : ''"
      />
      <v-select
        v-else-if="question.form_field == 'Select' && question.multiple"
        :items="selectOptions"
        chips
        multiple
        clearable
        deletable-chips
        outlined
        filled
        background-color="white"
        v-model="arrayValue"
        @input="onChange"
        :style="children ? 'font-size: 12px' : ''"
      />
      <v-text-field
        v-if="question.form_field == 'Select' && value == 'Other'"
        auto-grow
        rows="2"
        dense
        filled
        outlined
        type="text"
        background-color="white"
        v-model="alterValue"
        @input="onChange"
        :style="children ? 'font-size: 12px' : ''"
      />
      <!-- <radio-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Radio Input'"
    /> -->
      <!-- <checkbox-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Checkbox Input'"
    /> -->
      <div
        v-if="question.form_field == 'Toggle Input'"
        class="d-flex"
        :class="{ switch: !children }"
        v-show="condition"
        style="line-height: 1.5"
      >
        <v-switch
          class="pt-0 mt-0"
          v-model="value"
          @change="onChange"
          hide-details
        />
        <div
          class="ml-2"
          :style="children ? 'font-size: 12px; margin-top:4px;' : ''"
        >
          {{ value ? "Yes" : "No" }}
        </div>
      </div>
      <!-- <repeater-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Repeater Element'"
    /> -->
      <v-btn
        small
        @click="uploadFile"
        :loading="uploading"
        outlined
        rounded
        v-if="question.form_field == 'File Input'"
        >Upload Document</v-btn
      >
    </v-col>
    <v-col cols="12" v-if="question.form_field == 'File Input'" class="mb-5">
      <div v-if="question.multiple">
        <intake-photo-viewer
          :images="arrayValue"
          :onDeleteImage="onDeletedImage"
          v-if="profileType == 'Admin'"
        />
      </div>
      <div v-else-if="value">
        <intake-photo-viewer
          :images="[value]"
          :onDeleteImage="onDeletedImage"
          v-if="profileType == 'Admin'"
        />
      </div>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*, application/pdf"
        @change="onFileChanged"
        :multiple="question.multiple"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IntakePhotoViewer from "../client/IntakePhotoViewer.vue";
import EditableAvatar from "../core/EditableAvatar.vue";
import dateFormat from "dateformat";
export default {
  components: { IntakePhotoViewer, EditableAvatar },
  props: {
    question: Object,
    index: {
      type: Number,
      default: 0,
    },
    children: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectOptions: [],
      value: "",
      alterValue: "",
      menu: false,
      condition: true,
      required: false,
      rules: {
        required: (value) => {
          if (this.required && !value) return "*Required";
          return true;
        },
      },
      arrayValue: [],
      age: 0,
      uploading: false,
    };
  },
  methods: {
    ...mapActions({
      setIntake: "intake/setIntake",
      setUnsavedChanges: "intake/setUnsavedChanges",
      getTotalWIC: "intake/getTotalWIC",
      getBMI: "intake/getBMI",
      getAgeData: "intake/getAgeData",
      getNeedDays: "intake/getNeedDays",
      getValues: "variable/getValues",
      uploadDocumentFile: "intake/uploadDocumentFile",
      fetchAllOrganizations: "partner/fetchAllOrganizations",
    }),
    onChange() {
      if (this.question.multiple) {
        this.value = this.arrayValue.join();
      }
      if (this.question.key == "birthday") {
        if (this.value == null) this.value = "";
        if (this.alterValue == null) this.alterValue = "";
        console.log(this.alterValue, this.value);
        return;
        // this.age = this.getAge();
      }
      if (
        this.question.form_field == "Select" &&
        this.value == "Other" &&
        this.alterValue
      ) {
        this.setIntake({
          _id: this.question._id,
          value: `${this.alterValue}`,
          index: this.index,
        });
      } else if (
        this.question.form_field == "Select" &&
        this.value == "Notes" &&
        this.alterValue
      ) {
        this.setIntake({
          _id: this.question._id,
          value: `${this.alterValue}`,
          index: this.index,
        });
      } else {
        this.setIntake({
          _id: this.question._id,
          value: `${this.value}`,
          index: this.index,
        });
      }
      this.setUnsavedChanges();
    },
    getAge() {
      if (this.value == "" || this.value == undefined) return null;
      var today = new Date();
      var birthDate = new Date(this.value);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    onSaveDate() {
      console.log(this.value);
      var date = new Date(this.value + " EST");
      this.value = dateFormat(date, "mm/dd/yyyy");
      this.alterValue = dateFormat(date, "yyyy-mm-dd");
      this.age = this.getAge();
      this.$refs.menu.save(this.value);
      this.setIntake({
        _id: this.question._id,
        value: this.value,
        index: this.index,
      });
    },
    onSaveAltDate() {
      console.log(this.alterValue);
      var date = new Date(this.alterValue + " EST");
      this.value = dateFormat(date, "mm/dd/yyyy");
      this.alterValue = dateFormat(date, "yyyy-mm-dd");
      this.age = this.getAge();
      this.$refs.menu.save(this.value);
      this.setIntake({
        _id: this.question._id,
        value: this.value,
        index: this.index,
      });
    },
    onLoadData() {
      if (this.question.key == "total") {
        const oldValue = this.value;
        this.getTotalWIC().then((res) => {
          this.value = res;
          if (this.value != oldValue) {
            this.setIntake({
              _id: this.question._id,
              value: `${this.value}`,
              index: this.index,
            });
          }
        });
        this.getNeedDays()
          .then((res) => {
            // if (res) {
            this.alterValue = `${res}`;
            // console.log(this.alterValue);
            // }
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
      if (this.question.key == "bmi") {
        const oldValue = this.value;
        this.getBMI(this.index).then((res) => {
          this.value = res;
          if (this.value != oldValue) {
            this.setIntake({
              _id: this.question._id,
              value: `${this.value}`,
              index: this.index,
            });
          }
        });
      } else if (this.question.form_field === "Select") {
        if (
          this.question.multiple &&
          this.question.select_options == "#ORGANIZATION#"
        ) {
          this.fetchAllOrganizations()
            .then((res) => {
              this.selectOptions = res;
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        } else if (
          this.question.from_default_option &&
          this.selectOptions.length == 0
        ) {
          this.getValues({ title: this.question.default_option })
            .then((values) => {
              this.loading = false;
              this.selectOptions = values.map((v) => v.text).sort();
              if (!this.question.multiple) {
                this.selectOptions = ["", ...this.selectOptions];
              }
            })
            .catch((error) => {
              this.loading = false;
              if (
                error.response != undefined &&
                error.response.status === 401
              ) {
                this.tokenDialog = true;
              } else {
                console.log(error);
              }
            });
        } else if (this.question.select_options) {
          this.selectOptions = this.question.select_options.split(",");
          if (!this.question.multiple) {
            this.selectOptions = ["", ...this.selectOptions];
          }
        }
        if (
          this.question.multiple &&
          this.intakes[`${this.question._id}_${this.index}`]
        ) {
          this.arrayValue = this.intakes[
            `${this.question._id}_${this.index}`
          ].split(",");
        } else if (this.question.multiple) {
          this.arrayValue = [];
        } else {
          var value = this.intakes[`${this.question._id}_${this.index}`];
          if (
            value &&
            !this.selectOptions.includes(value) &&
            this.selectOptions.includes("Other")
          ) {
            this.value = "Other";
            this.alterValue = value;
          } else if (
            value &&
            !this.selectOptions.includes(value) &&
            this.selectOptions.includes("Notes")
          ) {
            this.value = "Notes";
            this.alterValue = value;
          } else {
            this.value = this.intakes[`${this.question._id}_${this.index}`];
          }
        }
      } else if (this.question.form_field == "Toggle Input") {
        this.value =
          this.intakes[`${this.question._id}_${this.index}`] == "true";
      } else if (this.question.form_field === "File Input") {
        if (
          this.question.multiple &&
          this.intakes[`${this.question._id}_${this.index}`]
        ) {
          this.arrayValue = this.intakes[
            `${this.question._id}_${this.index}`
          ].split(",");
        } else if (this.question.multiple) {
          this.arrayValue = [];
        } else {
          value = this.intakes[`${this.question._id}_${this.index}`];
        }
      } else {
        this.value = this.intakes[`${this.question._id}_${this.index}`];
      }
      if (this.question.conditional) {
        if (
          this.intakes[`${this.question.condition_id}_${this.index}`] ==
            "true" &&
          this.question.condition_value == "Is on"
        ) {
          this.condition = true;
        } else if (
          this.intakes[`${this.question.condition_id}_${this.index}`] ==
          this.question.condition_value
        ) {
          this.condition = true;
        } else {
          this.condition = false;
          this.value = "";
        }
      } else {
        this.condition = true;
      }
      if (this.question.key == "pregnant_or_nursing" && this.condition) {
        this.getAgeData(this.index).then((age) => {
          if (age < 10) this.condition = false;
        });
      }
      this.required = this.question.required;

      if (this.question.key == "birthday") {
        this.age = this.getAge();
        if (this.value) {
          var date = new Date(this.value + " EST");
          this.value = dateFormat(date, "mm/dd/yyyy");
          this.alterValue = dateFormat(date, "yyyy-mm-dd");
        }
      }
    },
    uploadFile() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.uploading = true;
        var formData = new FormData();
        if (this.question.multiple && e.target.files.length > 1) {
          for (var i = 0; i < e.target.files.length; i++) {
            formData.append("file", e.target.files[i]);
          }
        } else {
          formData.append("file", e.target.files[0]);
        }
        formData.append("index", this.index);
        formData.append("question_id", this.question._id);
        formData.append("client_id", this.client_id);
        formData.append(
          "multiple",
          this.question.multiple && e.target.files.length > 1
        );
        this.uploadDocumentFile(formData)
          .then((res) => {
            this.uploading = false;
            this.arrayValue.push(...res);
            this.onChange();
          })
          .catch((error) => {
            this.uploading = false;
            console.log(error.response.data.message);
          });
      }
    },
    onDeletedImage(image) {
      if (this.question.multiple) {
        this.arrayValue = this.arrayValue.filter((v) => image != v);
      } else {
        this.value = "";
      }
      this.onChange();
    },
  },
  computed: {
    ...mapGetters({
      intakes: "intake/getAllIntakes",
      client_id: "intake/selectedId",
      profileType: "auth/getType",
    }),
  },
  watch: {
    intakes() {
      this.onLoadData();
    },
  },
  mounted() {
    this.onLoadData();
  },
};
</script>
<style scoped>
.switch {
  display: flex;
  justify-content: flex-end;
}
.item-label {
  color: #472583;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
}
div >>> .v-date-picker-table {
  height: auto;
}
div >>> .v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}
</style>
