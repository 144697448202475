<template>
  <div class="d-flex align-center w-100">
    <editable-avatar
      :image="familyData.image"
      :size="40"
      :editable="false"
      class="ma-2"
    />
    <div class="ml-2">
      {{ familyData.login_id }}
    </div>
    <div class="ml-2 flex-grow">
      {{ familyData.name }}
    </div>
    <div class="ml-2">
      {{ familyData.relation }}
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    family: Object,
  },
  data() {
    return {
      familyData: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.familyData = this.family;
    this.getFamily({ _id: this.familyData._id })
      .then((res) => {
        this.familyData = { ...res, ...this.familyData };
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    ...mapActions({
      getFamily: "family/getFamily",
    }),
  },
};
</script>
<style scoped></style>
