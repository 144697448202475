<template>
  <div v-if="condition">
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="px-3"
      :show-expand="isMedical"
      :item-key="headers.length ? headers[0].value : null"
      :expanded.sync="expanded"
    >
      <template
        v-slot:[`item.${question._id}`]="{ item }"
        v-for="question in questions"
      >
        <intake-input-form
          :question="question"
          :index="item[question._id]"
          :children="true"
          :key="question._id"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex pb-5">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="onChitChatFamily(item)"
                small
                v-bind="attrs"
                v-on="on"
                :loading="getLoading('account', item)"
                ><v-icon>mdi-account-cog</v-icon></v-btn
              >
            </template>
            Chit Chat Family Account
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="onMoveUp(item)"
                small
                v-bind="attrs"
                v-on="on"
                :disabled="checkFirstIndex(item) || loading != null"
                :loading="getLoading('up', item)"
                ><v-icon>mdi-arrow-up</v-icon></v-btn
              >
            </template>
            Move up
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="onMoveDown(item)"
                small
                v-bind="attrs"
                v-on="on"
                :disabled="checkLastIndex(item) || loading != null"
                :loading="getLoading('down', item)"
                ><v-icon>mdi-arrow-down</v-icon></v-btn
              >
            </template>
            Move down
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="onDelete(item)"
                small
                v-bind="attrs"
                v-on="on"
                :disabled="loading != null"
                :loading="getLoading('delete', item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            Delete
          </v-tooltip>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="px-0">
          <v-data-table
            :headers="subHeaders"
            :items="subItems[item[headers[0].value]]"
            hide-default-footer
            class="px-3"
          >
            <template
              v-slot:[`item.${medicalQuestion._id}`]="{ item }"
              v-for="medicalQuestion in medicalQuestions"
            >
              <intake-input-form
                :question="medicalQuestion"
                :index="item[medicalQuestion._id]"
                :children="true"
                :key="medicalQuestion._id"
              />
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <chit-chat-family-account-dialog
      :dialog="familyDialog"
      :onCloseDialog="onCloseFamilyDialog"
      :index="selected"
      v-if="familyDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ error }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";
import ChitChatFamilyAccountDialog from "../client/ChitChatFamilyAccountDialog.vue";
import IntakeInputForm from "./IntakeInputForm.vue";
export default {
  components: {
    IntakeInputForm,
    ChitChatFamilyAccountDialog,
    ConfirmDeleteDialog,
  },
  props: {
    parent: Object,
  },
  data() {
    return {
      questions: [],
      medicalQuestions: [],
      headers: [],
      subHeaders: [],
      items: [],
      subItems: {},
      repeatCount: 0,
      condition: true,
      expanded: [],
      deleteDialog: false,
      familyDialog: false,
      selected: null,
      snackbar: false,
      error: null,
      loading: null,
    };
  },
  methods: {
    ...mapActions("intake", {
      moveUpChild: "moveUpChild",
      moveDownChild: "moveDownChild",
      deleteChild: "deleteChild",
    }),
    loadData() {
      this.repeatCount = parseInt(this.intakes[`${this.parent._id}_0`]) ?? 0;
      if (isNaN(this.repeatCount)) {
        this.repeatCount = 0;
      }
      if (this.parent.conditional) {
        if (
          this.intakes[`${this.parent.condition_id}_${this.index}`] == "true" &&
          this.parent.condition_value == "Is on"
        ) {
          this.condition = true;
        } else {
          this.condition = false;
        }
      } else {
        this.condition = true;
      }

      this.headers = [];
      for (let i = 0; i < this.questions.length; i++) {
        this.headers.push({
          text: this.questions[i].label,
          value: this.questions[i]._id,
          sortable: false,
        });
      }
      this.headers.push({ value: "action", text: "Action", sortable: false });
      if (this.isMedical) {
        this.headers.push({
          text: "",
          value: "data-table-expand",
        });
      }

      this.subHeaders = [];
      for (let i = 0; i < this.medicalQuestions.length; i++) {
        this.subHeaders.push({
          text: this.medicalQuestions[i].label,
          value: this.medicalQuestions[i]._id,
          sortable: false,
        });
      }

      this.items = [];
      for (let i = 1; i <= this.repeatCount; i++) {
        let obj = {};
        for (let j = 0; j < this.questions.length; j++) {
          obj[this.questions[j]._id] = i;
        }
        this.items.push(obj);
      }
      this.subItems = {};
      for (let i = 1; i <= this.repeatCount; i++) {
        let obj = {};
        for (let j = 0; j < this.medicalQuestions.length; j++) {
          obj[this.medicalQuestions[j]._id] = i;
        }
        this.subItems[this.items[i - 1][this.headers[0].value]] = [obj];
      }
    },
    onMoveUp(item) {
      const index = Object.values(item)[0];
      this.loading = { [`up-${index}`]: true };
      this.moveUpChild({ index })
        .then(() => {
          this.loading = null;
        })
        .catch((error) => {
          this.snackbar = true;
          this.error = error.response.data.message;
        });
    },
    onMoveDown(item) {
      const index = Object.values(item)[0];
      this.loading = { [`down-${index}`]: true };
      this.moveDownChild({ index })
        .then(() => {
          this.loading = null;
        })
        .catch((error) => {
          this.snackbar = true;
          this.error = error.response.data.message;
        });
    },
    onDelete(item) {
      this.selected = Object.values(item)[0];
      this.deleteDialog = true;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.loading = { [`delete-${this.selected}`]: true };
      this.deleteChild({ index: this.selected })
        .then(() => {
          this.loading = null;
        })
        .catch((error) => {
          this.snackbar = true;
          this.error = error.response.data.message;
        });
    },
    checkFirstIndex(item) {
      if (Object.values(item)[0] == 1) return true;
      return false;
    },
    checkLastIndex(item) {
      if (Object.values(item)[0] == this.repeatCount) return true;
      return false;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    getLoading(key, item) {
      const index = Object.values(item)[0];
      if (this.loading) {
        return this.loading[`${key}-${index}`];
      }
      return false;
    },
    onChitChatFamily(item) {
      this.selected = Object.values(item)[0];
      this.familyDialog = true;
    },
    onCloseFamilyDialog() {
      this.familyDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      allQuestions: "question/getAllQuestions",
      intakes: "intake/getAllIntakes",
      isMedical: "question/getMedical",
    }),
  },
  watch: {
    allQuestions(newValue) {
      this.questions = newValue
        .filter((q) => q.parent === this.parent._id && !q.medical)
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });

      this.medicalQuestions = this.allQuestions
        .filter((q) => q.parent === this.parent._id && q.medical)
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
    "$store.state.intake.intakes": {
      handler() {
        this.loadData();
      },
    },
    isMedical() {
      this.loadData();
    },
  },
  mounted() {
    this.questions = this.allQuestions
      .filter((q) => q.parent === this.parent._id && !q.medical)
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        }
        if (a.order) return -1;
        if (b.order) return 1;
        if (a._id > b._id) return 1;
        else if (a._id < b._id) return -1;
        return 0;
      });
    this.medicalQuestions = this.allQuestions
      .filter((q) => q.parent === this.parent._id && q.medical)
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        }
        if (a.order) return -1;
        if (b.order) return 1;
        if (a._id > b._id) return 1;
        else if (a._id < b._id) return -1;
        return 0;
      });

    this.loadData();
  },
};
</script>
<style scoped>
.item-small-label {
  color: #423f63;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
.v-data-table >>> tr:hover {
  background: transparent !important;
}
.v-data-table >>> td {
  border-bottom: 0px !important;
  padding: 0 5px !important;
}
.v-data-table >>> th {
  border-bottom: 0px !important;
}
.v-data-table >>> .v-data-table__expand-icon {
  margin-bottom: 20px !important;
}
</style>
