<template>
  <div v-viewer="options" class="images clearfix d-flex flex-wrap">
    <template v-for="image in images">
      <v-hover v-slot="{ hover }" :key="image">
        <div class="profile cell" @click="onSelectPhoto">
          <div @click="goToPDF(image)" v-if="checkPDF(image)">
            <pdf
              :src="image"
              :class="{ image: permission, 'disabled-image': !permission }"
              style="height: 100px; overflow: hidden;"
            />
          </div>
          <img
            :src="image"
            :data-source="image"
            :class="{ image: permission, 'disabled-image': !permission }"
            alt="Not available"
            v-else
          />
          <v-btn
            @click="onRemove(image)"
            class="remove_button"
            fab
            x-small
            v-show="hover && editable"
          >
            <v-icon v-text="'mdi-close'" size="16" />
          </v-btn>
        </div>
      </v-hover>
    </template>
    <v-progress-linear
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="3"
      v-if="loading"
    />
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <permission-dialog
      :dialog="permissionDialog"
      :onPassed="onPassedPermission"
      :onCloseDialog="onClosePermissionDialog"
      v-if="permissionDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";
import PermissionDialog from "./PermissionDialog.vue";
import pdf from "vue-pdf";

Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 9999,
  },
});

export default {
  components: { ConfirmDeleteDialog, PermissionDialog, pdf },
  props: {
    images: Array,
    onDeleteImage: Function,
  },
  data() {
    return {
      options: {
        toolbar: true,
        scalable: false,
        title: false,
        url: "data-source",
      },
      deleteDialog: false,
      errorMessage: null,
      snackbar: false,
      selected: null,
      loading: false,
      editable: true,
      permission: false,
      permissionDialog: false,
    };
  },
  methods: {
    toggleToolbar(toolbar) {
      // this.options = Object.assign({}, this.options, {toolbar})
      this.options.toolbar = toolbar;
    },
    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    onAddPhoto() {
      console.log("on add photo");
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.onUploadFile(e.target.files[0]);
      }
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.onDeleteImage(this.selected);
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onRemove(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onSelectPhoto() {
      if (!this.permission) {
        this.permissionDialog = true;
      }
    },
    onPassedPermission() {
      this.permissionDialog = false;
      this.permission = true;
    },
    onClosePermissionDialog() {
      this.permissionDialog = false;
    },
    checkPDF(url) {
      if (!url) return false;
      if (url.substr(url.length - 3).toLowerCase() == "pdf") return true;
      return false;
    },
    goToPDF(url) {
      if (this.permission) {
        const pdfUrl = "https://docs.google.com/gview?embedded=true&url=" + url;
        window.open(pdfUrl);
      }
    },
  },
  errorCaptured() {
    return false;
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cell {
  width: calc(30% - 10px);
  cursor: pointer;
  padding: 10px;
  display: inline-block;
}
.image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}
.disabled-image {
  pointer-events: none;
  width: 100%;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.profile {
  overflow: visible;
  position: relative;
  min-width: 144px;
}
.remove_button {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
